<template>
  <div class="page">
    <NavBar />

    <div class="container">
      <div class="title">账户</div>
      <div class="info q-pa-md q-mt-md">
        <div>#10095856</div>
        <div class="q-mt-sm">10000.00USD</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import NavBar from './components/NavBar'

export default {
  components: {
    NavBar
  },
  setup() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  background-color: #1F2937;
}

.container {
  padding: 15px;
}

.title {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.info {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
</style>